.activeTab .dYFsXY{box-shadow: #18b7f3 0px 1px 2px 0px; border: 1px solid #18b7f3;}
.activeTab .lleDyp{color: #585864;}
.activeTab .dYFsXY svg{fill: #18b7f3;}
.loding_img {
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 999;
    opacity: 0.7;
}
.fCxIoL {
    display: flex;
    flex-flow: column nowrap;
    -moz-box-flex: 1;
    flex-grow: 1;
    width: 100vw;
    height: 100vh;
}
.fbNykB {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    -moz-box-flex: 1;
    flex-grow: 1;
}
.kYBChq {
    text-align: center;
    padding: 0px 25px;
    font-size: 13px;
    font-weight: 500;
    color: rgb(103, 130, 164);
}
.kYBChq h4 {
    font-weight: 500;
}
.dZhGMm {
    display: flex;
    -moz-box-flex: 1;
    flex-grow: 1;
    max-height: 140px;
    flex-flow: column nowrap;
    -moz-box-pack: justify;
    justify-content: space-between;
    -moz-box-align: center;
    align-items: center;
}
.ghwxdp {
    min-height: 45px;
    min-width: 260px;
    font-size: 10pt;
    font-weight: bold;
    background-color: rgb(239, 57, 32) !important;
}
.fRQRQy {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border: medium none;
    text-decoration: none;
    border-radius: 4px;
    color: white;
    transition: all 0.4s ease 0s;
    padding: 8px;
}
.lfHCHS {
    color: rgb(103, 130, 164);
    font-size: 13px;
    font-weight: 500;
}
.bQKYwH {
    min-height: 45px;
    min-width: 260px;
    font-size: 10pt;
    font-weight: 200;
    color: rgb(34, 38, 68);
    border: 1px solid rgb(34, 38, 68);
}
.btVlSo {
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    border: 1px solid black;
    text-decoration: none;
    border-radius: 4px;
    background-color: transparent;
    color: black;
    transition: all 0.4s ease 0s;
    display: inline-block;
}
.iIXqXR {
    display: flex;
    flex-flow: row-reverse nowrap;
    -moz-box-align: center;
    align-items: center;
    background: rgb(250, 123, 122);
    overflow: hidden;
    font-weight: bold;
    font-size: 9pt;
    text-transform: uppercase;
    max-height: 20px;
    letter-spacing: 2px;
    color: #fff;
}
.crGvCx {
    padding: 0px 10px;
}

.fFwijF a {
    text-decoration: none;
    color: #000;
    padding: 8px 30px;
    margin-left: -4px;
}





.Khryt {
    padding: 23px;
}
div {
    display: block;
    background: #ffffff;
}
h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
.gxpldD {
    font-size: 15pt;
    font-weight: 400;
    color: rgb(34, 38, 68);
    margin: 0px;
}
p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

body {
    font-family: Rubik,Open Sans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}
.kBnzWP {
    position: relative;
    width: 92%;
    padding: 8px 12px;
    border: 1px solid rgb(157, 177, 202);
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
}
.Dgtqi {
    position: relative;
    height: 14px;
    color: rgb(29, 54, 80);
    font-size: 12px;
    letter-spacing: 0px;
}
.hgOeSS svg {
    width: 12px;
    height: 12px;
    margin: 0px 4px 2px 0px;
    vertical-align: middle;
}
.hBwHOd {
    position: absolute;
    width: 100%;
    top: 35px;
    left: -1px;
    overflow: hidden;
    display: none;
    background: white;
    box-shadow: rgb(168, 183, 199) 0px 2px 3px 0px;
    border-radius: 6px;
    border: 1px solid rgb(157, 177, 202);
    z-index: 9999;
}
.hmmQqY {
    display: flex;
    width: 100%;
    height: 30px;
    line-height: 0;
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    color: rgb(34, 38, 68);
}
.hYylqn {
    padding: 10px 0px 0px 12px;
}
.hgOeSS svg {
    width: 12px;
    height: 12px;
    margin: 0px 4px 2px 0px;
    vertical-align: middle;
}
.jEtgNd {
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    text-align: center;
    font-size: 9.75pt;
    margin-top: 22px;
    color: rgb(157, 177, 202);
}
.bEaVWB {
    width: 100px;
}
.dYFsXY {
    position: relative;
    cursor: pointer;
    margin: 0px auto;
    height: 43px;
    width: 43px;
    border-radius: 50%;
    border: 1px solid rgb(157, 177, 202);
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px 0px;
}
.dYFsXY svg {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: rgb(157, 177, 202);
}
svg:not(:root) {
    overflow-clip-margin: content-box;
    overflow: hidden;
}
.lleDyp {
    color: rgb(157, 177, 202);
    margin-top: 10px;
}
.dvvSRA {
    flex: 1 1 0%;
    display: flex;
    flex-flow: column nowrap;
}
textarea {
    writing-mode: horizontal-tb !important;
    font-style: ;
    font-variant-ligatures: ;
    font-variant-caps: ;
    font-variant-numeric: ;
    font-variant-east-asian: ;
    font-variant-alternates: ;
    font-weight: ;
    font-stretch: ;
    font-size: ;
    font-family: monospace;
    font-optical-sizing: ;
    font-kerning: ;
    font-feature-settings: ;
    font-variation-settings: ;
    text-rendering: auto;
    color: fieldtext;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    resize: auto;
    cursor: text;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    background-color: field;
    column-count: initial !important;
    margin: 0em;
    border-width: 1px;
    border-style: solid;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
    padding: 2px;
}
.gqVoKJ {
    font-size: 13px;
    font-weight: 100;
    color: rgb(103, 130, 164);
}
.jlCKpM {
    display: flex;
    margin-top: 17px;
}
.bUOkDH {
    display: inline-block;
    vertical-align: middle;
}
.eEGZDM {
    border: 0px;
    clip: rect(0px, 0px, 0px, 0px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0px;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
.Zkpnw {
    display: inline-block;
    height: 16px;
    width: 16px;
    border: 1px solid rgb(0, 189, 255);
    border-radius: 4px;
    transition: all 150ms ease 0s;
    cursor: pointer;
}
.Zkpnw .sc-dxZgTM {
    visibility: visible;
}
.hbfLYU {
    margin: 2px 0px 0px 2px;
    stroke-width: 2px;
}
.jlCKpM span {
    display: block;
    margin: 0px 0px 0px 7px;
    color: rgb(44, 48, 76);
    font-family: Rubik;
    font-size: 13px;
}
.jLuwoq {
    position: absolute;
    bottom: 0px;
    display: flex;
    width: 85%;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    background: white;
    padding: 24px;
}
button {
    appearance: auto;
    writing-mode: horizontal-tb !important;
    font-style: ;
    font-variant-ligatures: ;
    font-variant-caps: ;
    font-variant-numeric: ;
    font-variant-east-asian: ;
    font-variant-alternates: ;
    font-weight: ;
    font-stretch: ;
    font-size: ;
    font-family: ;
    font-optical-sizing: ;
    font-kerning: ;
    font-feature-settings: ;
    font-variation-settings: ;
    text-rendering: auto;
    color: buttontext;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    box-sizing: border-box;
    background-color: buttonface;
    margin: 0em;
    padding: 1px 6px;
    border-width: 2px;
    border-style: outset;
    border-color: buttonborder;
    border-image: initial;
}
.fRQRQy {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border: none;
    text-decoration: none;
    border-radius: 4px;
    color: white;
    transition: all 0.4s ease 0s;
    padding: 8px;
}
.eItEko {
    width: 100px;
    font-size: 13px;
    background: rgb(26, 57, 92);
}
.eyQrkP textarea {
    padding: 10px;
    width: 92%;
    height: 92px;
    font-size: 9pt;
    resize: none;
    margin-top: 22px;
}
.fFwijF {
    width: 100px;
    font-size: 9.75pt;
    margin-right: 10px;
}
.btVlSo {
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    border: 1px solid black;
    text-decoration: none;
    border-radius: 4px;
    background-color: transparent;
    color: black;
    transition: all 0.4s ease 0s;
    display: inline-block;
}
.jFcXnL {
    display: none;
    flex-flow: column nowrap;
    -webkit-box-flex: 1;
    flex-grow: 1;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0.98;
    background: white;
    -webkit-box-pack: start;
    justify-content: flex-start;
    z-index: 9999;
}
.fxgeKt {
    padding: 14px;
}
.gxpldD {
    font-size: 15pt;
    font-weight: 400;
    color: rgb(34, 38, 68);
    margin: 0px;
}
.fxgeKt div {
    color: rgb(34, 38, 68);
    font-size: 15pt;
    line-height: 15pt;
}
.fxgeKt p {
    color: rgb(103, 130, 164);
    font-size: 9.75pt;
}
.lnIxsJ {
    padding-top: 40px;
    display: flex;
    flex-flow: row;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: flex-start;
}
.lnIxsJ svg {
    width: 50%;
    height: 50%;
}
.ctUQXa {
    position: absolute;
    bottom: 0px;
    display: flex;
    width: 85%;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    background: white;
    padding: 24px;
}
.eYigwC {
    width: 100px;
    font-size: 13px;
    background: rgb(26, 57, 92);
}
.fRQRQy {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border: none;
    text-decoration: none;
    border-radius: 4px;
    color: white;
    transition: all 0.4s ease 0s;
    padding: 8px;
}
.jqvyBx {
    width: 100px;
    font-size: 9.75pt;
}
.btVlSo {
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    border: 1px solid black;
    text-decoration: none;
    border-radius: 4px;
    background-color: transparent;
    color: black;
    transition: all 0.4s ease 0s;
    display: inline-block;
}
input[type="checkbox" i] {
    background-color: initial;
    cursor: default;
    appearance: auto;
    box-sizing: border-box;
    margin: 3px 3px 3px 4px;
    padding: initial;
    border: initial;
}
.Zkpnw .sc-dxZgTM {
    visibility: visible;
}
.hbfLYU {
    margin: 2px 0px 0px 2px;
    stroke-width: 2px;
}


.jlCKpM span {
    display: block;
    margin: 0px 0px 0px 7px;
    color: rgb(44, 48, 76);
    font-family: Rubik;
    font-size: 13px;
}
.jLuwoq {
    position: absolute;
    bottom: 0px;
    display: flex;
    width: 85%;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    background: white;
    padding: 24px;
}
button {
    appearance: auto;
    writing-mode: horizontal-tb !important;
    font-style: ;
    font-variant-ligatures: ;
    font-variant-caps: ;
    font-variant-numeric: ;
    font-variant-east-asian: ;
    font-variant-alternates: ;
    font-weight: ;
    font-stretch: ;
    font-size: ;
    font-family: ;
    font-optical-sizing: ;
    font-kerning: ;
    font-feature-settings: ;
    font-variation-settings: ;
    text-rendering: auto;
    color: buttontext;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    box-sizing: border-box;
    background-color: buttonface;
    margin: 0em;
    padding: 1px 6px;
    border-width: 2px;
    border-style: outset;
    border-color: buttonborder;
    border-image: initial;
}
.fRQRQy {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border: none;
    text-decoration: none;
    border-radius: 4px;
    color: white;
    transition: all 0.4s ease 0s;
    padding: 8px;
}
.eItEko {
    width: 100px;
    font-size: 13px;
    background: rgb(26, 57, 92);
}
.eyQrkP textarea {
    padding: 10px;
    width: 92%;
    height: 92px;
    font-size: 9pt;
    resize: none;
    margin-top: 22px;
}
.fFwijF {
    width: 100px;
    font-size: 9.75pt;
    margin-right: 10px;
}
.btVlSo {
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    border: 1px solid black;
    text-decoration: none;
    border-radius: 4px;
    background-color: transparent;
    color: black;
    transition: all 0.4s ease 0s;
    display: inline-block;
}
.jFcXnL {
    display: none;
    flex-flow: column nowrap;
    -webkit-box-flex: 1;
    flex-grow: 1;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0.98;
    background: white;
    -webkit-box-pack: start;
    justify-content: flex-start;
    z-index: 9999;
}
.fxgeKt {
    padding: 14px;
}
.gxpldD {
    font-size: 15pt;
    font-weight: 400;
    color: rgb(34, 38, 68);
    margin: 0px;
}
.fxgeKt div {
    color: rgb(34, 38, 68);
    font-size: 15pt;
    line-height: 15pt;
}
.fxgeKt p {
    color: rgb(103, 130, 164);
    font-size: 9.75pt;
}
.lnIxsJ {
    padding-top: 40px;
    display: flex;
    flex-flow: row;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: flex-start;
}
.lnIxsJ svg {
    width: 50%;
    height: 50%;
}
.ctUQXa {
    position: absolute;
    bottom: 0px;
    display: flex;
    width: 85%;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    background: white;
    padding: 24px;
}
.eYigwC {
    width: 100px;
    font-size: 13px;
    background: rgb(26, 57, 92);
}
.fRQRQy {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border: none;
    text-decoration: none;
    border-radius: 4px;
    color: white;
    transition: all 0.4s ease 0s;
    padding: 8px;
}
.jqvyBx {
    width: 100px;
    font-size: 9.75pt;
}
.btVlSo {
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    border: 1px solid black;
    text-decoration: none;
    border-radius: 4px;
    background-color: transparent;
    color: black;
    transition: all 0.4s ease 0s;
    display: inline-block;
}
.Zkpnw .sc-dxZgTM {
    visibility: visible;
}
.hbfLYU {
    margin: 2px 0px 0px 2px;
    stroke-width: 2px;
}
.esXqhP {
    position: absolute;
    width: 100%;
    top: 35px;
    left: -1px;
    overflow: hidden;
    display: block;
    background: white;
    box-shadow: rgb(168, 183, 199) 0px 2px 3px 0px;
    border-radius: 6px;
    border: 1px solid rgb(157, 177, 202);
    z-index: 9999;
}
.hmmQqY {
    display: flex;
    width: 100%;
    height: 30px;
    line-height: 0;
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    color: rgb(34, 38, 68);
}
.hYylqn {
    padding: 10px 0px 0px 12px;
}
.hgOeSS svg {
    width: 12px;
    height: 12px;
    margin: 0px 4px 2px 0px;
    vertical-align: middle;
}
.folgTb {
    position: absolute;
    height: 14px;
    width: 14px;
    top: 0px;
    right: 0px;
}
.hBwHOd.hidedropdawn{display: block;}
.hbfLYU.hidedropdawn{display: none;}
.displaynone{display: none;}
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

